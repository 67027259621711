import { initializeApp } from "firebase/app";
//import firebase from 'firebase/compat/app';

/* Old way of doing it */
/* import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
 */
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
//import { getAnalytics } from 'firebase/analytics';

import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const config = {};

if (process.env.REACT_APP_ENV === "dev") {
  console.log(`🔥🔥🐉 DEVELOPMENT MODE 🐉🔥🔥`);

  //console.log('this is my api  --->', process.env.REACT_APP_DEV_API_KEY)

  config.apiKey = process.env.REACT_APP_DEV_API_KEY;
  config.authDomain = process.env.REACT_APP_DEV_AUTH_DOMAIN;
  config.databaseURL = process.env.REACT_APP_DEV_DATABASE_URL;
  config.projectId = process.env.REACT_APP_DEV_PROJECT_ID;
  config.storageBucket = process.env.REACT_APP_DEV_STORAGE_BUCKET;
  config.messagingSenderId = process.env.REACT_APP_DEV_MESSAGING_SENDER_ID;
  config.appId = process.env.REACT_APP_APP_DEV_ID;
  config.measurementId = process.env.REACT_APP_DEV_MEASUREMENT_ID;
} else {
  config.apiKey = process.env.REACT_APP_API_KEY;
  config.authDomain = process.env.REACT_APP_AUTH_DOMAIN;
  config.databaseURL = process.env.REACT_APP_DATABASE_URL;
  config.projectId = process.env.REACT_APP_PROJECT_ID;
  config.storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
  config.messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
  config.appId = process.env.REACT_APP_APP_ID;
  config.measurementId = process.env.REACT_APP_MEASUREMENT_ID;
}

const firebase = initializeApp(config);
export const auth = getAuth(firebase);
export const firestore = getFirestore(firebase);
export const storage = getStorage(firebase);
export const functions = getFunctions(firebase);

//export const analytics = getAnalytics(firebase);

//Test the cloud functions localy  ->   >>> firebase emulators:start --only functions
// console.log(`💾 To run ☁️☁️ CLOUD functions ☁️☁️ don't forget to run >>> firebase emulators:start --only functions`);
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);

//console.log('this is my firestore --> ', firebase);
//firebase.firestore().settings({ timestampsInSnapshots: true });
